


@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&family=Roboto+Slab:wght@900&family=Source+Sans+Pro&display=swap');


@media (prefers-color-scheme: dark) {
  body,
  div,
  p,
  h2,
  h3,
  footer {
    color: lightgray!important;
    background-color: #333344!important; 
  }
  #navContainer {
    color: #22222D!important;
  }
  a:link {
	color: cyan!important;
  }
}




header,
h1,
h2,
h3 {
	font-family: 'Roboto Slab', serif;
	font-weight: 500;
}

body {
	margin: 0;
	font-family: 'Source Sans Pro', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, 'Courier New', monospace; 
}

label {
  visibility: hidden;
  font-size: 0;
}

a:link {
	color: blue;
}

a:visited {
	color: inherit;
}

a:visited:hover {
	color: white; 
}

a:link:hover {
	color: pink;
}



/* APP */

.App {
  text-align: center;
}

#contentSpacer {
  height: 12vh;
}

/* FOOTER */


.FooterContainer {
  padding: 4vw;
	margin-bottom: 8vw;
}

#footer_copyright {
  float: left;
}

#footer_emailme {
  float: right;
}


/* NAV / TABS */

#navContainer {
  font-size: clamp(16pt, 10vw, 24pt);
  text-align: center;
  vertical-align: middle;
  line-height: 2.75ex;
  float: left;
  width: 100vw;
  margin-top: 0px;
  position: fixed;
  left: 0;
  top: 0;
  height: 2.5ex;
}

.navBar {
  background-color: #9999AA;
  display: block;
  height: 2.5ex;
  padding-right: 2em;
}

.NavHeader,
.TabHider {
  float: left; 
  display: inline;
  margin: 0px;
}

.HomeTab {
  float: right;
  border-radius: 0px;
  border-bottom-left-radius: 1ex;
  height: 3.4ex;
  line-height: 2.5ex;
  margin: 0px;
  margin-right: -0.2em;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 2.5em; 
  border-width: 0px;
  
  
	font-family: 'Roboto Slab', serif;
	font-weight: 900;
	font-size: 100%;
  line-height: 0px;
  text-align: center;
  vertical-align: middle;
  
  background-color: #1111CC;
  color: lightgray;
  
  padding-left: 0.1em;
  padding-right: 0.4em;
  padding-top: 0.1ex;
  padding-bottom: 0.1ex;
}

.NavHeader {
  text-align: left;
  height: 4ex;
  margin-left: 0.8em;
  user-select: none;
}

.TabHider {
  background-color: inherit;
  --border-bottom-right-radius: 1ex;
  width: 2em;
  border-width: 0px;
  
  height: inherit;
  font-family: 'roboto', sans-serif;
  font-weight: bold;
  font-size: inherit;
  text-align: center;
  vertical-align: top;
  line-height: 1ex;
}

#tabContainer {
  line-height: 4ex;
  color: lightgray;
  vertical-align: middle;
  font-size: 60%;
  font-family: 'Roboto Slab';
  font-weight: 500;
  
  --background-color: #2a2a55;
  background-color: #9999AA;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  width: 10em;
  position: absolute;
  border-bottom-right-radius: 1vw;
  --border-top-right-radius: 1vw;
  
}

.NavContainerOut #tabContainer {
  transform: translateX(0);
  transition: transform 0.6s;
}

.NavContainerIn #tabContainer {
  transform: translateX(calc(-1*(12em + 5vw)));
  transition: transform 0.6s;
}

.Tab {
  border-radius: 4pt;
  border-width: 0px;
  
  font: inherit;
  line-height: 3ex;
  text-align: center;
  vertical-align: middle;
  
  --background-color: #1111CC;
  --color: lightgray;
  height: 4ex;
  width: 8em;
  
  --margin-left: 2.5vw;
  --margin-right: 2.5vw;
  margin-bottom: 3px;
  
  padding-left: clamp(4pt, 1vw, 12pt);
  padding-right: clamp(4pt, 1vw, 12pt);
  padding-top: clamp(2pt, 0.5vw, 6pt);
  padding-bottom: clamp(2pt, 0.5vw, 6pt);
}

.Tab,
.TabHider {
  background-color: #1111CC;
  color: lightgray;
  transition: background-color 0.2s;
  transition: color 0.6s;
}

.NavContainerOut .TabHider {
  background-color: inherit;
  color: inherit;
  transition: background-color 0.2s;
  transition: color 0.6s;
}

.Tab:hover,
.TabHider:hover,
.HomeTab:hover {
  background-color: #AAAAFF;
  transition: background-color 0.2s;
}

.Tab:active,
.TabHider:active,
.HomeTab:active {
  background-color: #CCCCFF;
  color: #666666;
  transition: background-color 0.2s;
  transition: color 0.6s;
}

/* CONTENT CONTAINER */

.ContentContainer {
  padding-left: 2vw;
  padding-right: 2vw;
}

/* BLOG LINKS */

.BlogLink {
	background: darkgray;
	margin-top: 2ex;
	margin-bottom: 2ex;
	margin-right: 2ex;
	margin-left: 2ex;
	display: inline-block;
	width: 9ex;
	padding: 1ex;
	position: static;
  border-radius: 4pt;
  border-width: 0px;
}

/* HIDER HEADER */

.HiderHidden {
  display: none;
}

a.HiderHidden,
a.HiderNotHidden {
  color: inherit;
}

.UtitleContainer .UtitleText {
	text-decoration: underline;
}

.UtitleContainer .UtitleTitle {
	display: none;
}

.UtitleContainer .UtitleText,
.UtitleContainer .UtitleTitle {
	color: inherit;
}

.UtitleContainer:hover .UtitleTitle {
	display: inline;
	font-style: italic;
	background-color: white;
}


